import React from "react"
import { graphql } from "gatsby"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "Large Global Bank"
const pageTitle = "Large Global Bank"
const subTitle = "Cloud Infrastructure "
const para ="Enabled and optimize CI/CD pipeline to use Immutable Images Stand up the OpenShift environments and on Azure cloud Enable logging, monitoring, Auditing and Alerting on Azure cloud and on-prem instances. Utilize HashiCorp Terraform for infrastructure.Optimize the infrastructure scaling so the application can meet peak demand and scale back down during slower periods Utilize Azure Key Vault for storing secrets and certificates. Automate the certificate deployment process using power script and shell script into a multitude of VM’s to enable SSL/ TLS communication. DevOps and CI/CD principles for zero down time during deployments. The Atyeti team has transformed the current build and deployment process to align with Cloud deployment model.Automated the certificate rotation using Terraform and Azure Key Vault Automated the entire infrastructure provisioning."
const blockquote = `"Automated the certificate rotation using Terraform and Azure Key Vault Automated
the entire infrastructure provisioning."`

const cardItems = [
  {
    id: 1,
    heading: `The Need`,
    listItems: (
      <>
        <p>
          {/* <Icon.Square /> */}
          Our client wanted to perform seamless infrastructure provisioning on
          the cloud and wanted to move away from patch management
        </p>
        {/* <img  src={MapImg}/> */}
      </>
    ),
  },

  {
    id: 1,
    heading: `The Approach`,
    listItems: (
      <>
      <p>
          We use Terraform and created reusable modules to provision
          infrastructure on multiple cloud platforms and utilized custom data
          section for post-production deployments
        </p>
      </>
    ),
  },
  {
    id: 3,
    heading: `The Process:`,
    listItems: (
      <>
        <p>
          We integrated Terraform with the CI/CD processes for infrastructure
          provisioning and created immutable images that can be deployed on-prem
          and on the cloud
        </p>
      </>
    ),
  },
  {
    id: 4,
    heading: `Result`,
    listItems: (
      <>
        <p>
          Terraform for infrastructure provisioning and immutable image
          creation; whilst used Vault for storing secrets used by CI/CD pipeline
          and terraform to provision infrastructure on cloud.
        </p>
      </>
    ),
  },
]

const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query SixthQuery {
    casedetailsJson(slug: { eq: "large-global-bank" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
